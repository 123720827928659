import styled from 'styled-components'

export const Table = styled.div`
  margin-top: 74px;
  width: 100%;
  max-width: 1045px;
  margin: auto;

  button {
    margin: auto;
    margin-top: 42px;
    width: 225px;
  }

  @media (max-width: 1048px) {
    padding: 0px 15px;
  }
`

export const TableHeader = styled.div`
  background: #ae9962;
  display: flex;
  padding: 24px;
  margin-top: 73px;
  justify-content: space-between;

  div {
    display: flex;
    align-items: center;
  }

  div:first-child {
    width: 42.58%;
  }

  div:last-child {
    width: 48.516%;
  }

  h3 {
    font-size: 18px;
    line-height: 22px;
    color: #fff;
  }

  @media (max-width: 968px) {
    div:first-child {
      width: 48%;
    }

    h3 {
      font-size: 16px;
      line-height: 18px;
    }
  }

  @media (max-width: 868px) {
    h3 {
      font-size: 14px;
      line-height: 16px;
    }
  }

  @media (max-width: 768px) {
    padding: 19px;
  }
`

const Content = styled.div`
  display: flex;
  justify-content: space-between;
  padding: 24px;

  div:first-child {
    width: 41.556%;
  }

  div:last-child {
    display: flex;
    width: 48.5%;
    justify-content: space-between;
    align-items: center;
  }

  @media (max-width: 968px) {
    h4 {
      font-size: 16px;
      line-height: 18px;
    }
  }

  @media (max-width: 868px) {
    h4 {
      font-size: 14px;
      line-height: 16px;
    }
  }

  @media (max-width: 768px) {
    padding: 19px;
    p {
      font-size: 13px;
    }
  }

  @media (max-width: 512px) {
    flex-direction: column;

    div:first-child,
    div:last-child {
      width: 100%;
      justify-content: space-around;
      text-align: center;
    }

    div:first-child {
      margin-bottom: 15px;
    }
  }

  @media (max-width: 425px) {
    flex-direction: column;

    div:first-child,
    div:last-child {
      justify-content: space-between;
    }
  }
`

export const TableSubheader = styled(Content)`
  display: flex;
  align-items: center;
  border-bottom: 4px solid #ae9962;
`

export const TableRow = styled(Content)`
  padding: 36px 24px;
  border-bottom: 1px solid #d8dad9;
  align-items: center;

  p {
    line-height: 18px;
    font-weight: 325;
    font-size: 15px;
    color: #333333;
  }

  &:nth-of-type(even) {
    background: #f7f6f6;
    border-top: 1px solid #d8dad9;
  }
`
