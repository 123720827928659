import styled from 'styled-components'

export const Container = styled.section`
  h1,
  h2,
  h4 {
    font-weight: normal;
    color: #ae9962;
  }

  h1 {
    font-size: 28px;
    line-height: 34px;
  }

  h2 {
    font-size: 22px;
    line-height: 26px;
  }

  h4 {
    font-size: 18px;
    line-height: 22px;
    font-weight: bold;
  }

  p {
    font-size: 15px;
    line-height: 28px;
    color: #333333;
  }

  @media (max-width: 768px) {
    p {
      line-height: 24px;
    }
  }
`
