import styled from 'styled-components'
import Button from '../Button'

export default styled(Button)`
  background: #ae9962;
  border: 1px solid #ae9962;
  color: #fff !important;

  &:hover {
    border-color: #836f3d;
  }
`
