import React from 'react'
import Layout from '../components/Layout'
import SEO from '../components/SEO'
import Banner from '../components/Banner'
import Content from '../sections/Index/Content'

const IndexPage = () => (
  <Layout>
    <SEO title="Master International" />
    <Banner
      heading="MASTER INTERNATIONAL É OUTRO NÍVEL"
      height={435}
      filename="index-banner"
      pageTitle=""
    />
    <Content />
  </Layout>
)

export default IndexPage
