import styled from 'styled-components'
import Button from '../Button'

export default styled(Button).attrs(props => ({
  to: props.to,
}))`
  border: 1px solid #836f3d;
  background: transparent;
  color: #836f3d !important;

  &:hover {
    color: #fff !important;
  }
`
