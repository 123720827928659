import React from 'react'
import { Container } from './styles'
import Sobre from './Sobre'
import Caracteristicas from './Caracteristicas'

function Content() {
  return (
    <Container>
      <Sobre />
      <Caracteristicas />
    </Container>
  )
}

export default Content
