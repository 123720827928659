import React from 'react'
import Youtube from 'react-youtube'
import ButtonPrimary from '../../../../components/ButtonPrimary'
import ButtonOutline from '../../../../components/ButtonOutline'
import {
  Container,
  Video,
  VideoInner,
  Colaboracao,
  ColaboracaoInner,
  Blocks,
  Banner,
  BannerInner,
} from './styles'
import bemvindo from '../../../../images/index/bem-vindo.png'

function Sobre() {
  return (
    <Container>
      <Video>
        <VideoInner>
          <Youtube videoId="7Hbtf8TJlDo" />
          <p>
            A Care Plus é uma operadora de saúde premium, líder no segmento
            corporativo de cuidados com a saúde no Brasil. Desde 2016, faz parte
            da Bupa Global, uma das maiores seguradoras de saúde do mundo,
            presente em 190 países. Somos reconhecidos como um provedor de
            soluções inovadoras de cuidados de saúde para empresas, em sua
            maioria multinacionais, que adotam o benefício de saúde como uma
            ferramenta para atrair e reter colaboradores.
          </p>
        </VideoInner>
      </Video>
      <Colaboracao>
        <ColaboracaoInner>
          <h1>
            COLABORAÇÃO{' '}
            <strong>
              BUPA E <br /> BLUE CROSS BLUE SHIELD
            </strong>{' '}
            GLOBAL
          </h1>

          <p>
            Dois dos nomes mais respeitados no setor de assistência médica, a
            Bupa Global e a Blue Cross Blue Shield Global se uniram para
            oferecer produtos e serviços de saúde de alta qualidade. Esta
            parceria surgiu de uma ambição compartilhada para defender e
            oferecer acesso a cuidados de saúde de nível internacional, bem como
            atender às necessidades de saúde de clientes com mentalidade e
            mobilidade globais.
          </p>

          <ButtonPrimary to="/caracteristicas">Saiba mais</ButtonPrimary>

          <p>
            A Bupa Global é o braço internacional de seguros de saúde da Bupa.
            Oferecemos aos clientes em todo o mundo produtos e serviços para
            pessoas conectadas e com mentalidade global que querem a cobertura
            mais premium e acesso aos cuidados de saúde que precisam a qualquer
            momento, em qualquer lugar do mundo, seja em casa ou quando estudam,
            vivem, viajam ou trabalham no exterior.
          </p>

          <p>
            A Bupa Global tem escritórios em todo o mundo, incluindo Londres e
            Brighton (Reino Unido), Miami (EUA), Copenhague (Dinamarca), Dubai
            (Emirados Árabes Unidos, em parceria com a OIC) e Hong Kong (China),
            bem como escritórios regionais na China Continental, Cingapura,
            Egito, México, República Dominicana, Bolívia, Brasil, Panamá,
            Guatemala e Equador. A Bupa Global adquiriu recentemente a Care
            Plus, seguradora de saúde líder no mercado no Brasil, que atende
            mais de 400 empresas com cerca de 100.000 membros.
          </p>

          <p>
            Desde 1929, as empresas Blue Cross Blue Shield(BCBS) fornecem
            cobertura de saúde a segurados nos Estados Unidos, permitindo-lhes
            viver sem preocupações e sem medo. As empresas Blue Cross Blue
            Shieldoferecem uma abordagem personalizada na saúde baseados nas
            necessidades das comunidades onde seus segurados vivem e trabalham.
            Eles trabalham em estreita colaboração com hospitais e médicos nas
            comunidades que servem para fornecer cuidados de saúde acessíveis e
            de qualidade.
          </p>

          <p />
        </ColaboracaoInner>
        <Blocks>
          <div id="objetivos">
            <h2>OBJETIVO</h2>
            <p>
              O nosso objetivo são vidas mais longas, com saúde e felicidade.
              Cumprimos essa promessa ao proteger os nossos beneficiários,
              proporcionando-lhes uma ampla variedade de serviços
              personalizados, bem como cuidando da sua saúde durante toda a
              vida. Como a sua aliada na saúde.
            </p>
          </div>

          <div id="nosso-time">
            <h2>NOSSO TIME</h2>
            <p>
              A experiência da nossa equipe é essencial para proporcionar
              atenção à saúde de melhor qualidade. Empregamos mais de 84 mil
              pessoas em todo o mundo, as quais proporcionam experiência,
              atenção e serviços de qualidade. Somos considerados uma das
              melhores empresas para trabalhar.
            </p>
          </div>
        </Blocks>
      </Colaboracao>
      <Banner>
        <BannerInner>
          <img src={bemvindo} alt="" />
          <div>
            <h2>BEM-VINDO AO MASTER INTERNATIONAL</h2>
            <p>
              Garantimos a cobertura de cuidados para a sua saúde, da mais alta
              qualidade no mundo, para expatriados e suas famílias, viajantes
              internacionais frequentes e pessoas de negócios com necessidades e
              horários exigentes.
            </p>
            <ButtonOutline>Saiba mais</ButtonOutline>
          </div>
        </BannerInner>
      </Banner>
    </Container>
  )
}

export default Sobre
