import styled from 'styled-components'
import BackgroundImage from 'gatsby-background-image'

export const Container = styled.section`
  margin-top: 133px;
  width: 100%;
  height: auto;
`

export const Banner = styled(BackgroundImage)`
  display: flex;
  align-items: center;
  height: 543px;

  @media (max-width: 868px) {
    height: 453px;
  }
`

export const Inner = styled.div`
  width: 100%;
  max-width: 997px;
  margin: auto;

  @media (max-width: 1048px) {
    padding: 0px 15px;
  }
`

export const Block = styled.div`
  background: #ae9962;
  padding: 33px 23px;
  width: 374px;
  height: 374px;
  display: flex;
  flex-direction: column;

  h2,
  p {
    color: #fff;
  }

  h2 {
    font-size: 28px;
    line-height: 34px;
    font-family: Gotham Light;
    margin-bottom: 50px;
  }

  p {
    font-size: 15px;
  }

  @media (max-width: 392px) {
    width: 100%;
    height: auto;
  }
`

export const Benefits = styled.div`
  margin-top: 65px;

  h1 {
    text-align: center;
  }

  a {
    margin-top: 44px;
    display: flex;
    margin: 44px auto 0;
  }
`
