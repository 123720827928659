import styled from 'styled-components'

export const Container = styled.section``

export const Video = styled.section`
  padding: 67px 64px;
  background: #eeeded;

  @media (max-width: 512px) {
    padding: 37px 34px;
  }
`

export const VideoInner = styled.div`
  width: 100%;
  max-width: 1045px;
  margin: auto;

  iframe {
    width: 100%;
    height: 100%;
    height: 565px;
    margin-bottom: 59px;
  }

  p {
    padding: 0px 24px;
  }

  @media (max-width: 868px) {
    iframe {
      height: 393px;
    }
  }

  @media (max-width: 668px) {
    iframe {
      height: 303px;
    }
  }

  @media (max-width: 512px) {
    iframe {
      height: 217px;
    }
  }

  @media (max-width: 325px) {
    p {
      padding: 0;
    }
  }
`

export const Colaboracao = styled.section`
  padding-top: 85px;
  padding-bottom: 93px;
  width: 100%;
`

export const ColaboracaoInner = styled.div`
  display: flex;
  margin: auto;
  max-width: 689px;
  align-items: center;
  flex-direction: column;

  h1 {
    margin-bottom: 60px;
    text-align: center;
  }

  p {
    text-align: center;

    &:not(:first-child) {
      margin-bottom: 40px;
    }
  }

  a {
    align-self: center;
    display: flex;
    margin-bottom: 70px;
  }

  @media (max-width: 1048px) {
    padding: 0 15px;
  }
`

export const Blocks = styled.div`
  margin: auto;
  max-width: 1045px;
  display: flex;
  justify-content: space-between;
  margin-top: 113px;

  h2 {
    margin-top: 43px;
    margin-bottom: 57px;
  }

  div {
    flex: 0 0 46.89%;
    border-top: 5px solid #ae9962;
  }

  @media (max-width: 1048px) {
    padding: 0 15px;
  }

  @media (max-width: 768px) {
    padding: 0;
    flex-direction: column;
    width: 70%;

    div:first-child {
      margin-bottom: 55px;
    }
  }

  @media (max-width: 512px) {
    width: 90%;
  }
`

export const Banner = styled.div`
  background: #eeeded;
  display: flex;
`

export const BannerInner = styled.div`
  width: 100%;
  max-width: 997px;
  margin: auto;
  display: flex;
  justify-content: space-between;

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 41.927%;

    h2,
    p {
      margin-bottom: 35px;
    }

    button {
      border-color: #ae9962;
      color: #ae9962;
    }
  }

  @media (max-width: 868px) {
    align-items: center;

    img {
      width: 417px;
      height: 100%;
    }

    div {
      h2,
      p {
        margin-bottom: 20px;
      }
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;

    img {
      width: 60%;
      margin-bottom: 35px;
    }

    div {
      width: 60%;
      margin-bottom: 35px;

      h2,
      p {
        margin-bottom: 35px;
      }

      button {
        display: flex;
        align-self: center;
      }
    }
  }

  @media (max-width: 568px) {
    img,
    div {
      width: 80%;
    }
  }

  @media (max-width: 425px) {
    img {
      width: 100%;
    }

    div {
      width: 90%;
      text-align: center;
    }
  }
`
