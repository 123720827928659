import React from 'react'
import { useStaticQuery, graphql } from 'gatsby'
import ButtonPrimary from '../../../../components/ButtonPrimary'
import {
  Table,
  TableHeader,
  TableSubheader,
  TableRow,
} from '../../../../components/Table'
import { Container, Banner, Inner, Block, Benefits } from './styles'

function Caracteristicas() {
  const { file } = useStaticQuery(graphql`
    {
      file(
        relativeDirectory: { eq: "index" }
        name: { eq: "caracteristicas-banner" }
      ) {
        childImageSharp {
          fluid {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)

  return (
    <Container>
      <Banner Tag="div" fluid={file.childImageSharp.fluid}>
        <Inner>
          <Block>
            <h2>
              SUA <br /> COBERTURA
            </h2>
            <p>
              A Care Plus oferece uma gama de opções anuais de franquia e
              coparticipação para auxiliar a sua empresa a gerenciar o benefício
              do plano de saúde.
            </p>
          </Block>
        </Inner>
      </Banner>
      <Benefits>
        <Inner>
          <h1>MASTER INTERNATIONAL</h1>
        </Inner>
        <Table>
          <TableHeader>
            <div>
              <h3>LIMITE DA COBERTURA POR BENEFICIÁRIO POR ANO DE CONTRATO</h3>
            </div>
            <div>
              <h3>VERIFIQUE O LIMITE DA COBERTURAPARA O SEU PLANO DE SAÚDE</h3>
            </div>
          </TableHeader>
          <TableSubheader>
            <div>
              <h4>
                BENEFÍCIOS E LIMITAÇÕES <br /> DE PACIENTE INTERNADO
              </h4>
            </div>
            <div>
              <h4>COBERTURA GLOBAL</h4>
              <h4>LIVRE ESCOLHA</h4>
            </div>
          </TableSubheader>
          <TableRow>
            <div>
              <p>
                <strong>Serviços hospitalares</strong>
              </p>
            </div>
            <div>
              <p>Pagamento integral</p>
              <p>Tabela de indenização</p>
            </div>
          </TableRow>
          <TableRow>
            <div>
              <p>
                <strong>Quarto hospitalar e refeições</strong> <br /> (Quarto
                particular) – diária
              </p>
            </div>
            <div>
              <p>Pagamento integral</p>
              <p>Tabela de indenização</p>
            </div>
          </TableRow>
          <TableRow>
            <div>
              <p>
                <strong>Hospital – Unidade de cuidados intensivos</strong>
                <br />
                (Cuidados intensivos, terapia intensiva, cuidados coronários e
                unidade de alta dependência) – diária
              </p>
            </div>
            <div>
              <p>Pagamento integral</p>
              <p>Tabela de indenização</p>
            </div>
          </TableRow>
          <TableRow>
            <div>
              <p>
                <strong>Sala de cirurgia</strong>
                <br />
                Medicamentos e curativos cirúrgicos
              </p>
            </div>
            <div>
              <p>Pagamento integral</p>
              <p>Tabela de indenização</p>
            </div>
          </TableRow>
          <TableRow>
            <div>
              <p>
                <strong>Honorários e taxas</strong>
                <br />
                De cirurgia, incluindo os cirurgiões, auxiliares e anestesistas
              </p>
            </div>
            <div>
              <p>Pagamento integral</p>
              <p>Tabela de indenização</p>
            </div>
          </TableRow>
        </Table>
        <ButtonPrimary to="/caracteristicas">Ver lista completa</ButtonPrimary>
      </Benefits>
    </Container>
  )
}

export default Caracteristicas
